import React from 'react';

const ServicesTwoAbout = () => {
    return (
        <>
            <section className="about-area pt-50">
                <div className="container">
                    <div className="row ">
                        <div className="col-xl-6 col-lg-6 col-md-12 d-flex">
                            <div className="section-title section-title-m-0 mb-30 pos-rel text-end align-self-center">
                                <div className="section-icon">
                                    <img className="section-back-icon back-icon-right"
                                         src="img/section/section-back-icon.png" alt=""/>
                                </div>
                                <div className="section-text section-text-small pos-rel">
                                    <h5>Better solutions!</h5>
                                    <h2>Cost-effective and efficient way to manage your mobile business needs</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="facalty-text mb-10">
                                <p>
                                    We understand the importance of having a reliable and secure mobile communications
                                    platform. Our solutions provide a cost-effective and efficient way to manage your
                                    mobile business needs. We have developed a suite of services that can be tailored to
                                    meet your needs and ensure that you are always connected.
                                </p>
                                <p>
                                    Our USSD, SMS, Airtime and Mobile Data solutions provide fast and secure access to a
                                    range of services such as account management, balance inquiries, payments, loyalty
                                    programs, customer service, marketing campaigns and more.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default ServicesTwoAbout;
